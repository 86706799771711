<template>
  <div>
    <section-headed-description
      :title="translations.referencesTitle"
      :description="documentToHtmlString(translations.referencesDescription)"
      :icon="icon"
    />
    <section-download-file
      v-for="(item, index) in translations.referencesItems"
      :key="index"
      :title="item.title"
      :file="getImgUrl(item.file)"
      :img="getImgUrl(item.logo)"
    />
  </div>
</template>

<script>
  import SectionDownloadFile from '@/views/sections/reusable/DownloadFile'
  import SectionHeadedDescription from '@/views/sections/reusable/HeadedDescription'
  import { mapState } from 'vuex'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  import getImgUrl from '@/utils/getImgUrl.js'
  import { STORE_NAMESPACE_REFERENCES } from '@/views/references/Index.vue'

  export default {
    name: 'SectionReferencesFiles',
    components: {
      SectionDownloadFile,
      SectionHeadedDescription,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_REFERENCES, [
        'translations',
      ]),
    },
    methods: {
      documentToHtmlString,
      getImgUrl,
    },
    data: () => ({
      icon: 'mdi-handshake',
    }),
  }
</script>
